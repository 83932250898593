
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Generator from "@/components/reusable/GeneratorRenewal.vue";
import Contents from "@/components/reusable/ContentsRenewal.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "renewal-owned-building-house-insurance-component",
  components: {
    Form,
    ErrorMessage,
    Field,
    TotalPremiumWidget,
    GoBackButton,
    ScheduleInfoModal,
    Generator,
    ContentsBuildingModal,
    Contents,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      buildingDescription: "",
      displayForm: true,
      displayError: false,
      policies: [],
      policySummary: {
        packages: [],
        generators: [],
      },
      policyDetails: [],
      totalPremium: 0.0 as number,
      underwriters: [],
      buildingReinstatementValueRate: 0.001,
      buildingReinstatementRatePublicId: "",
      lossOfRentValueRate: 0.001,
      lossOfRentRatePublicId: "",
      personalEffectsRate: 0.045,
      personalEffectsRatePublicId: "",
      cashValueRate: 0.006,
      cashValueRatePublicId: "",
      thirdPartLiabilityValueRate: 0.00075,
      thirdPartLiabilityValueRatePublicId: "",
      generatorRate: 0.0025,
      generatorRatePublicId: "",
      phoneRate: 0.05,
      phoneRatePublicId: "",
      laptopRate: 0.02,
      laptopRatePublicId: "",
      cameraRate: 0.01,
      cameraRatePublicId: "",
      inverterRate: 0.0025,
      inverterRatePublicId: "",
      musicalInstrumentRate: 0.01,
      musicalInstrumentRatePublicId: "",
      jewelryRate: 0.01,
      jewelryRatePublicId: "",
      policy: "" as string,
      publicId: this.$route.params.publicId as string,
      housePolicyId: "",
      underwriterId: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      thirdPartyLiabilityLimit: "",
      schedule: "",
      buildingType: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      buildingReinstatementValue: "",
      lossOfRentValue: "",
      imagesOfBuilding: "",
      benefitLimit: "",
      rate: 0.001,
      medicalRate: 0.15,
      includeMedicalExpenses: false,
      result: {
        personalAccidentCartItemPublicId: "",
        hasPersonalAccidentCartItem: true || (false as boolean),
      },
      coopPublicId: "",
      getPackage: {
        publicId: "",
      },
      packageId: "",
      cameraDetails: [],
      jewelryDetails: [],
      laptopOrTabletDetails: [],
      musicalInstrumentDetails: [],
      phoneDetails: [],
      inverterDetails: [],
      generators: [],
    };
  },
  setup() {
    const { findObjectByKeyValue } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Owned Building", [
        "Insurance Packages",
        "House Insurance Policy",
        "Renewal",
      ]);
    });

    const metadataValidator = Yup.object().shape({
      buildingDetails: Yup.array().of(
        Yup.object({
          buildingType: Yup.mixed()
            .required()
            .label("Type of building"),
          state: Yup.string()
            .required()
            .label("State"),
          localGovernmentArea: Yup.string()
            .required()
            .label("L.G.A"),
          ward: Yup.string()
            .required()
            .label("Town/City"),
          streetAddress: Yup.string()
            .required()
            .label("Street address"),
          buildingReinstatementValue: Yup.string()
            .required()
            .label("Building reinstatement value"),
          lossOfRentValue: Yup.string()
            .required()
            .label("Loss of rent value"),
          imagesOfBuilding: Yup.mixed()
            .required()
            .label("Photo of building"),
          houseHoldGoodsAndPersonalEffectsValue: Yup.string()
            .required()
            // .min(10000)
            .label("Contents value"),
          cashValue: Yup.string()
            .notRequired()
            // .min(10000)
            .label("Cash value"),
          thirdPartyLiabilityLimit: Yup.string()
            .notRequired()
            // .min(10000)
            .label("Third-party liability value"),
          generatorValue: Yup.string()
            .notRequired()
            .label("Generator value"),
          generatorBrand: Yup.mixed()
            .notRequired()
            .label("Generator brand"),
          generatorPhoto: Yup.mixed()
            .notRequired()
            .label("Photo of generator"),
          generatorReceiptPhoto: Yup.mixed()
            .notRequired()
            .label("Photo of generator receipt"),
        }).strict()
      ),
    });

    return {
      metadataValidator,
      findObjectByKeyValue,
    };
  },
  created() {
    // Get owned building house insurance policies only
    this.getPolicies();

    //Set page title
    document.title =
      "Renewal: Owned Building Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/householder/OWN`)
          .then(({ data }) => {
            //Assign data to props
            if (data.data != "") {
              this.displayError = false;
              this.policies = data.data;

              if (this.publicId !== undefined && this.publicId !== null) {
                this.policy = this.publicId;
                this.getPolicyInformation(this.publicId);
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.housePolicyId = value;

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_RENEWAL_ROUTE}/${value}`)
          .then(({ data }) => {
            // Dynsmicslly assign the data object keys to the variables
            for (const key in data.data) {
              if (
                Object.prototype.hasOwnProperty.call(data.data, key) &&
                key in this
              ) {
                (this as any)[key] = data.data[key];
              }
            }

            this.policySummary = data.data.policySummary;
            this.policyDetails = data.data.items;
            this.displayForm = data.data != "" ? true : false;
            this.getPackage = this.findObjectByKeyValue(
              this.policySummary?.packages,
              "brokerReference",
              "PACK-1"
            );

            this.packageId = this.getPackage?.publicId;
            this.underwriterId = data.data.leadInsurer?.publicId;
            this.totalPremium = data.data.policySummary.premium;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 5) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          underwriter: this.underwriterId,
          package: this.packageId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    getRates() {
      //Fetch list of underwriters for house building insurance package
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_PERSONAL_ACCIDENT_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.rate = data.data.accidentalDeath.value;
            this.medicalRate = data.data.reasonableMedicalExpenses.value;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    mdeicalPremium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setTimeout(() => {
        this.$nextTick(() => (event.target.value = newValue));
      }, 0.1);

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 3) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          underwriter: this.underwriterId,
          package: this.packageId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }

      if (this.includeMedicalExpenses) {
        // Set check to false
        this.includeMedicalExpenses = !this.includeMedicalExpenses;
        this.medicalExpenses();
      }
    },
    medicalExpenses() {
      this.includeMedicalExpenses = !this.includeMedicalExpenses;

      const paValue = document.getElementById(
        "personal-accident-value"
      ) as HTMLElement;

      const medicalExpensesPremiumText = document.getElementById(
        "medical-expenses-premium"
      ) as HTMLElement;

      // const totalPremiumText = document.getElementById(
      //   "total-premium"
      // ) as HTMLElement;

      if (this.includeMedicalExpenses) {
        const premiumValue = variables.removeComma(paValue.textContent);
        // const value = this.medicalRate * premiumValue;
        const value = premiumValue;
        let newPremium = variables.totalPremiumValue() + value;
        newPremium = parseFloat(newPremium.toFixed(2));

        if (isNaN(newPremium) == false) {
          // medicalExpensesPremiumText.textContent = this.addCommaToNumberString(
          //   value
          // );
          // this.totalPremium = newPremium;
          // totalPremiumText.textContent = this.addCommaToNumberString(
          //   newPremium
          // );

          const payload = {
            businessClass: "HOM",
            underwriter: this.underwriterId,
            package: this.publicId,
            benefitLimit: premiumValue,
            includeMedicalExpenses: true,
            sumInsured: 0,
          } as object;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.PREMIUM_ROUTE, payload)
              .then(({ data }) => {
                variables.premiumCalculator(
                  0,
                  data.data.grossPremium,
                  "medical-expenses-premium"
                );

                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      } else {
        this.totalPremium = variables.totalPremiumValue();
        medicalExpensesPremiumText.textContent = "0";
      }
    },
    formSubmit(e) {
      e.preventDefault();
      const submitButton1 = document.getElementById("home_submit_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        const form = document.forms[0];
        const formData = new FormData(form);
        formData.append("package", "BUI");
        formData.append("packageType", "OWN");
        formData.append("businessClass", "HOM");
        formData.append("businessType", "HOU");

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            variables.CART_ADDITION_ROUTE + formData.get("housePolicyId"),
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
            .then((response) => {
              variables.toastAlert(response.data.data.message, "success");
              this.$router.push({
                name: "insurance-packages-policy-period",
                params: {
                  publicId: response.data.data.cartItemPublicId,
                },
              });
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    scheduleSelected(event) {
      this.schedule = event.target.files[0];
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
  },
});
