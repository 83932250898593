import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Phone = _resolveComponent("Phone")!
  const _component_Laptop = _resolveComponent("Laptop")!
  const _component_Jewelry = _resolveComponent("Jewelry")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_MusicalInstrument = _resolveComponent("MusicalInstrument")!
  const _component_Inverter = _resolveComponent("Inverter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Phone, {
      phones: _ctx.phones,
      "phone-rate": _ctx.phoneRate,
      "phone-rate-public-id": _ctx.phoneRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["phones", "phone-rate", "phone-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"]),
    _createVNode(_component_Laptop, {
      laptops: _ctx.laptops,
      "laptop-rate": _ctx.laptopRate,
      "laptop-rate-public-id": _ctx.laptopRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["laptops", "laptop-rate", "laptop-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"]),
    _createVNode(_component_Jewelry, {
      jewelries: _ctx.jewelries,
      "jewelry-rate": _ctx.jewelryRate,
      "jewelry-rate-public-id": _ctx.jewelryRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["jewelries", "jewelry-rate", "jewelry-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"]),
    _createVNode(_component_Camera, {
      cameras: _ctx.cameras,
      "camera-rate": _ctx.cameraRate,
      "camera-rate-public-id": _ctx.cameraRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["cameras", "camera-rate", "camera-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"]),
    _createVNode(_component_MusicalInstrument, {
      "musical-instruments": _ctx.musicalInstruments,
      "musical-instrument-rate": _ctx.musicalInstrumentRate,
      "musical-instrument-rate-public-id": _ctx.musicalInstrumentRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["musical-instruments", "musical-instrument-rate", "musical-instrument-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"]),
    _createVNode(_component_Inverter, {
      inverters: _ctx.inverters,
      "inverter-rate": _ctx.inverterRate,
      "inverter-rate-public-id": _ctx.inverterRatePublicId,
      "policy-id": _ctx.policyId,
      "grand-premium": _ctx.grandPremium,
      "premium-calculation-route": _ctx.premiumCalculationRoute,
      "package-id": _ctx.packageId
    }, null, 8, ["inverters", "inverter-rate", "inverter-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route", "package-id"])
  ]))
}