
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Inverters from "@/views/reusable/buy-insurance/house-insurance/inverters.json";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "inverter-renewal-content-component",
  components: { Field, ErrorMessage },
  props: {
    inverterRate: Number,
    inverterRatePublicId: String,
    policyId: String,
    grandPremium: Number,
    premiumCalculationRoute: String,
    packageId: String,
    inverters: Array,
  },
  data() {
    return {
      invertersList: Inverters,
      inverterDetails: [
        {
          status: "",
          brand: "",
          imageOfReceipt: "",
          serialNumber: "",
          value: 0.0 as number,
        },
      ],
      totalPremium: 0.0 as number,
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    setTimeout(() => {
      this.totalPremium = this.grandPremium as number;

      if (this.inverters !== undefined && !this.isEmptyArray(this.inverters)) {
        this.inverterDetails = [...this.inverters] as any;
      }
    }, 1500);
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 5) {
        const payload = {
          businessClass: "HOM",
          value: value,
          rate: event.target.accessKey,
          package: this.packageId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    addDynamicField() {
      this.inverterDetails.push({
        status: "",
        brand: "",
        serialNumber: "",
        imageOfReceipt: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-inverter-" + counter).remove();

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    imageSelected(event, position) {
      this.inverterDetails[position].imageOfReceipt = event.target.files[0];
    },
  },
});
