
import { defineComponent } from "vue";
import Phone from "@/components/reusable/PhoneRenewal.vue";
import Laptop from "@/components/reusable/LaptopRenewal.vue";
import Jewelry from "@/components/reusable/JewelryRenewal.vue";
import Camera from "@/components/reusable/CameraRenewal.vue";
import MusicalInstrument from "@/components/reusable/MusicalInstrumentRenewal.vue";
import Inverter from "@/components/reusable/InverterRenewal.vue";

export default defineComponent({
  name: "house-insurance-contents-renewal-component",
  components: { Phone, Laptop, Jewelry, Camera, MusicalInstrument, Inverter },
  props: {
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    policyId: String,
    phoneRatePublicId: String,
    laptopRatePublicId: String,
    cameraRatePublicId: String,
    inverterRatePublicId: String,
    musicalInstrumentRatePublicId: String,
    jewelryRatePublicId: String,
    grandPremium: Number,
    premiumCalculationRoute: String,
    packageId: String,
    phones: Array,
    laptops: Array,
    cameras: Array,
    inverters: Array,
    musicalInstruments: Array,
    jewelries: Array,
  },
});
